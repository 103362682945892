let init = false;

const initGTM = (id, dataLayerName) => {
  if (init) {
    return;
  }

  /* eslint-disable */
  (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
  new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
  j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
  'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
  }(window,document,'script', dataLayerName, id))
  /* eslint-enable */

  init = true;
};

export const onInitialClientRender = (_, {
  id,
  dataLayerName = 'dataLayer',
  includeInDevelopment,
}) => {
  if (process.env.GATSBY_ENV === 'production' || includeInDevelopment) {
    const load = () => initGTM(id, dataLayerName);

    setTimeout(() => {
      if ('requestIdleCallback' in window) {
        requestIdleCallback(load);
      } else {
        load();
      }
    }, 5000);

    window.addEventListener('scroll', () => {
      load();
    }, { once: true });
  }
};

export const onRouteUpdate = ({ prevLocation }, {
  id,
  dataLayerName = 'dataLayer',
  includeInDevelopment,
}) => {
  if (!prevLocation) {
    return;
  }

  if (process.env.GATSBY_ENV === 'production' || includeInDevelopment) {
    // just to be sure.
    initGTM(id, dataLayerName);

    // wrap inside a timeout to ensure the title has properly been changed
    setTimeout(() => {
      window[dataLayerName].push({ event: 'gatsby-route-change' });
    }, 50);
  }
};
