// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---gatsby-theme-hipex-src-pages-404-js": () => import("./../../gatsby-theme-hipex/src/pages/404.js" /* webpackChunkName: "component---gatsby-theme-hipex-src-pages-404-js" */),
  "component---gatsby-theme-hipex-src-templates-blog-js": () => import("./../../gatsby-theme-hipex/src/templates/blog.js" /* webpackChunkName: "component---gatsby-theme-hipex-src-templates-blog-js" */),
  "component---gatsby-theme-hipex-src-templates-blog-overview-js": () => import("./../../gatsby-theme-hipex/src/templates/blog-overview.js" /* webpackChunkName: "component---gatsby-theme-hipex-src-templates-blog-overview-js" */),
  "component---gatsby-theme-hipex-src-templates-page-js": () => import("./../../gatsby-theme-hipex/src/templates/page.js" /* webpackChunkName: "component---gatsby-theme-hipex-src-templates-page-js" */)
}

